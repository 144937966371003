import axios from '@/api/config/interceptor'
import { host, riskHost } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//步骤一
//上传excel解析成运单列表
// export const transformWaybillExcel = (params) => {
//     return new Promise((resolve, reject) => {
//         axios.post(`${host}/api/File/ExcelPars`, params).then(res => {
//             if (res.code == 0) {
//                 resolve(res.data)
//             } else {
//                 reject(res)
//             }
//         })
//     })
// }
export const transformWaybillExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBill/ImportExcel`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询货主六要素
export const consignSixElement = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/ConsignSixElement`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//下载错误运单
export const downErrorBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/DownErrorBills`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传pdf电子合同
export const uploadContract = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/UploadPdf`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//风控审核
export const riskVerify = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${riskHost}/api/WayBillRisk/Risk`, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res != null && res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch(e => {
            reject(e)
        })
    })
}

//风控审核校验时间
export const riskVerifyTime = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${riskHost}/api/WayBillRisk/RiskTime`, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch(e => {
            reject(e)
        })
    })
}

//步骤二
//获取被服务商拒绝的原因
export const getTaskRejectOpinion = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetTaskRejectOpinion?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查看服务商是否有电子账户和电子签名
export const getUserApprove = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetUserApprove?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取省市区
export const getArea = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBill/GetCityInfo`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取地区子节点
export const getAreaChildren = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBill/GetChildDistrict?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取付款方式
export const getDictInfoListForNine = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetDictInfoListForNine?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询开票信息
export const getProviderInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBillPlus/GetProviderInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//保存开票信息
export const saveInvoceInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/AddInvoices`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//计算运单总税费和总运费
export const billFeeCount = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/BillFees`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


//步骤三
export const lotNumForBillDetals = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/LotNumForBillDetals?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更改支付模式时选动态计算金额
export const getPayAmountByDictCode = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetPayAmountByDictCode?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询运单列表
export const getWayBillfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/GetWayBillfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//货主重新选择服务商(其实就是用开票单号查询运单)
export const returnBillsInfoDeatils = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/ReturnBillsInfoDeatils`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//货主放弃付款
export const giveUpPayMent = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/GiveUpPayMent`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//货主确认付款
export const comfirmPays = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBillPlus/Payments?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询结算申请信息
export const getPaymentInfo = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBillPlus/GetPaymentInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询合计
export const billsCount = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBillPlus/BillsCount?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//步骤四

//货主查询服务商发的是电子发票还是纸质票（货主）
export const getInvoiceStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetInvoiceStatus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取电子回单
export const invoiceUrl = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/InvoiceUrl`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//发票统计信息（货主）
export const invoiceCount = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/InvoiceCount`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//货主驳回服务商开的票
export const sendBack = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/SendBack`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//货主确认收票
export const confirmReceipt = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/Receipt?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//货主查询服务商是否开票完成
export const billCompleted = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBillPlus/Completeds?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//服务商查询运单详情
export const printInvoiceTaskDetailList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/GetInvoiceDetailing`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//根据打票单号查询电子发票
export const invoiceImageList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/LookInvoiceImage`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//下载发票运单表格
export const billDown = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/BillDown`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 导入轨迹
export const ImportTrace = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBill/ImportTrace`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取导入轨迹权限
export const GetImportTraceAuth = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBill/GetImportTraceAuth`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 导入凭证
export const importEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBill/ImportEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取发票类型 GET
export const GetTicketType = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBillPlus/GetTicketType?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 上传url凭证
export const AddEvidenceByUrls = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/AddEvidenceByUrls`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 合并批次
export const MergeTable = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBill/MergeTable`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 运单列表
export const GetBillList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBill/GetBillList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载运单列表
export const DwonBillList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBill/DwonBillList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 批量同意/拒绝
export const AgreeAll = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/AgreeAll`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台审核发票--同意
export const PlatAuthInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/PlatAuthInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台审核发票--拒绝
export const PlatAuthInvoiceReject = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/PlatAuthInvoiceReject`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台审核发票--同意--批量
export const PlatAuthInvoiceBatch = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/PlatAuthInvoiceBatch`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台审核发票--拒绝--批量
export const PlatAuthInvoiceRejectBatch = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/PlatAuthInvoiceRejectBatch`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询技术服务费率
export const GetSysValueAsync = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/Sys/GetSysValueAsync/12`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 无服务商付款
export const PutPaymentWithout = (params, importBatchId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/PutPaymentWithout/PutPaymentWithoutAsync/${params}/${importBatchId}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取无服务商数据列表
export const GetNoSupplierList = (TaskSceneTypeId, params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/WayBill/CalculateAmountAsync/${TaskSceneTypeId}/${params}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 校验司机税务登记状态
export const CheckTaxReg = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/CheckTaxReg`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 校验司机税务登记状态 --错误下载
export const CheckTaxRegDownd = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/CheckTaxRegDownd`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 新增临登审核
export const DriverTaxRegCheckAdd = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DriverTaxRegCheck/Add`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 临登审核列表
export const DriverTaxRegCheckList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DriverTaxRegCheck/List`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 临登审核列表-下载
export const DriverTaxRegCheckListDownload = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DriverTaxRegCheck/ListDownload`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 审核
export const DriverTaxRegCheckValidate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/DriverTaxRegCheck/Validate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载税务表格
export const RefundWaybill = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/InvoiceTask/DownloadTaxTable?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发票识别
export const DisInvoices = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/DisInvoices`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 修改发票七要素描述
export const UpdateInvoiceDesc = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/UpdateInvoiceDesc`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 自动组合之前，校验是否已存在发票
export const AutoCheckInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/AutoCheckInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

