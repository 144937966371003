<template>
    <div class="driverTaxRegCheck">
        <div class="facilityBox">
            <!-- form表单 -->
            <el-form class="manageForm" :model="manageForm" ref="manageForm" :inline="true">
                <el-form-item label="司机名称" prop="driverName" label-width="90px">
                    <el-input class="fromInp" v-model="manageForm.driverName" placeholder="请输入司机名称"></el-input>
                </el-form-item>
                <el-form-item label="司机手机号" prop="driverPhone" label-width="90px">
                    <el-input class="fromInp" v-model="manageForm.driverPhone" placeholder="请输入司机手机号"></el-input>
                </el-form-item>
                <el-form-item label="审核状态" prop="status">
                    <el-select v-model="manageForm.status" clearable placeholder="请选择审核状态" style="width: 240px">
                        <el-option v-for="item in statuslist" :key="item.Code" :label="item.Name" :value="item.Code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业名称" prop="ownerName" label-width="90px">
                    <el-input class="fromInp" v-model="manageForm.ownerName" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item label="服务商名称" prop="supplierName" label-width="90px">
                    <el-input class="fromInp" v-model="manageForm.supplierName" placeholder="请输入服务商名称"></el-input>
                </el-form-item>
                <div>
                    <el-form-item label="提交时间" prop="datetime" label-width="90px">
                        <el-date-picker v-model="dateTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                            value-format="yyyy-MM-dd" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label-width="10px">
                        <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
                        <el-button type="primary" size="medium"
                            @click="() => ($refs['manageForm'].resetFields(), dateTime = ['', ''])"
                            icon="el-icon-delete">清空</el-button>
                        <el-button type="primary" size="medium" icon="el-icon-download" @click="downloadExcel()"
                            :loading="loading">
                            下载表格</el-button>
                        <el-button type="primary" :disabled="!multipleSelection.length" size="medium"
                            @click="toCheck('', '10')" icon="el-icon-folder-checked">批量通过</el-button>
                        <el-button type="danger" :disabled="!multipleSelection.length" size="medium"
                            @click="toCheck('', '1')" icon="el-icon-folder-delete">批量拒绝</el-button>
                    </el-form-item>
                </div>
            </el-form>
            <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
                @selection-change="handleSelectionChange" ref="multipleTable"  max-height="550">
                <el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
                <el-table-column type="index" align="center" label="序号" fixed width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" fixed label="司机姓名" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverPhone" label="司机手机号" width="180"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="OwnerName" label="企业名称" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SupplierName" label="服务商名称" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Reson" label="异常原因" width="250" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="AgentPhone" label="审核状态" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.Status == '0' ? 'primary' : (scope.row.Status == '1' ? 'danger' : 'success')">{{
                                scope.row.StatusName }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Remark" label="审核备注" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CreateTime" label="提交时间" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" label="操作" width="180" fixed="right">
                    <template slot-scope="scope" v-if="scope.row.Status == 0">
                        <el-button type="primary" size="small" @click="toCheck(scope.row, '10')">通过</el-button>
                        <el-button type="danger" size="small" @click="toCheck(scope.row, '1')">拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top:10px">
                <!-- 分页 -->
                <el-pagination background class="pagination" @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page" :page-sizes="[10, 20, 30, 40, 50, 100]"
                    @size-change="paginationSizeChange" :page-size="pagination.pagesize"
                    layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog width="700px" title="审核备注" :visible.sync="remarkModal" append-to-body class="remarkModal">
            <el-input type="textarea" maxlength="200" placeholder="请输入审核备注，限制200个汉字" v-model="remark"></el-input>
            <div style="display: flex; justify-content: center; margin-top: 16px;">
                <el-button @click="remarkModal = false">取消</el-button>
                <el-button type="primary" @click="submitCheck">确定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    DriverTaxRegCheckList,
    DriverTaxRegCheckListDownload,
    DriverTaxRegCheckValidate
} from "@/api/waybill/waybillManage/index";
export default {
    data() {
        return {
            statuslist: [
                {
                    Code: '',
                    Name: '全部'
                },
                {
                    Code: '0',
                    Name: '未审核'
                },
                {
                    Code: '10',
                    Name: '已通过'
                },
                {
                    Code: '1',
                    Name: '已拒绝'
                }
            ],
            manageForm: {
                status: '',
                driverName: '',
                driverPhone: '',
                ownerName: '',
                supplierName: '',
            },
            dateTime: ['', ''],
            tableData: [],
            pagination: {
                page: 1,
                pagesize: 10,
                total: 0,
            },
            loading: false,
            remarkModal: false,
            remark: '',
            multipleSelection: [],
            ids: [],
            checkType: ''
        }
    },
    created() {
        this.getDriverTaxRegCheckList()
    },
    methods: {
        // 获取列表
        getDriverTaxRegCheckList() {
            let params = {
                pageIndex: this.pagination.page,
                pageSize: this.pagination.pagesize,
                ...this.manageForm,
                createTimeStart: (this.dateTime && this.dateTime[0]) ? this.dateTime[0] + ' 00:00:00' : '',
                createTimeEnd: (this.dateTime && this.dateTime[1]) ? this.dateTime[1] + ' 23:59:59' : '',
            };
            this.loading = true;
            DriverTaxRegCheckList(params)
                .then((res) => {
                    this.tableData = res.data.DataList;
                    this.loading = false;
                    this.pagination.total = Number(res.data.TotalCount);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 下载Excel
        downloadExcel() {
            this.loading = true;
            let data = {
                pageIndex: this.pagination.page,
                pageSize: this.pagination.pagesize,
                ...this.manageForm,
                createTimeStart: (this.dateTime && this.dateTime[0]) ? this.dateTime[0] + ' 00:00:00' : '',
                createTimeEnd: (this.dateTime && this.dateTime[1]) ? this.dateTime[1] + ' 23:59:59' : '',
            };
            DriverTaxRegCheckListDownload(data)
                .then((res) => {
                    window.location.href = res.data
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        //搜索
        search() {
            this.pagination.page = 1
            this.getDriverTaxRegCheckList()
        },
        //分页回调
        handleCurrentChange(val) {
            this.pagination.page = val
            this.getDriverTaxRegCheckList()
        },
        paginationSizeChange(val) {
            this.pagination.pagesize = val;
            this.getDriverTaxRegCheckList()
        },
        // 选中表格
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        selectable(row, index) {
            if (row.Status == 0) {
                return true
            } else {
                return false
            }
        },
        toCheck(item, type) {
            this.checkType = type
            if (item) {
                this.ids = [item.Id]
            } else {
                this.multipleSelection.map(it => {
                    this.ids.push(it.Id)
                })
            }
            this.remarkModal = true
            this.remark = ''
        },
        // 提交审核操作 
        submitCheck() {
            this.$confirm('确定进行该操作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '请求中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                DriverTaxRegCheckValidate({
                    ids: this.ids,
                    status: this.checkType,
                    remark: this.remark
                }).then(() => {
                    this.remarkModal = false
                    this.search()
                    this.$message.success('请求成功!');
                    this.ids = []
                    this.multipleSelection = []
                }).finally(() => {
                    loading.close()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        }
    }
}
</script>
<style lang="scss">
.remarkModal {
    .el-dialog__body {
        padding-top: 10px !important;
    }
}
</style>